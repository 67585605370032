import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Builder from './components/Builder';
import AdvertisementDetail from './components/AdvertisementDetail'
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
	return (
		<Router basename="/">
			<Switch>
				<Route exact path="/" component={Builder} />
				<Route path="/advertisement/:adId" component={AdvertisementDetail} />
			</Switch>
		</Router>
	);
}
