import _ from 'lodash';

const getBuilders = async (builder_subdomain) => {
    console.log('builder_subdomain', builder_subdomain);
    const request = await fetch(`https://backend.myplotpic.com/api/getBuilder`);
    const Builders = await request.json();
    const BuildersData = await Builders.data;
    //console.log('BuildersData', BuildersData);
    const filteredBuilder = _.filter(BuildersData, (builder) => builder.subdomain.trim() === builder_subdomain);
    return filteredBuilder[0];
}

const getAdvertisement = async () => {
    const request = await fetch(`https://backend.myplotpic.com/api/getAdvertisement`);
    const Advertisement = await request.json();
    return Advertisement;
}



export { getBuilders, getAdvertisement }