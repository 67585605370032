import React from 'react'
import _ from 'lodash';
import './Advertisement.css'
import { Link } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'

export default function Advertisement(props) {
    const file_path = 'https://backend.myplotpic.com/uploads/';
    const builderAds = props.builderAds || {};
    return (
        <>
            {
                builderAds ? (<Row>
                    {
                        _.map(builderAds, function (advertisement) {
                            const project_image = file_path + advertisement.files[0];
                            const project_dateNew = new Date(advertisement.createdAt);
                            const project_date = project_dateNew.toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' });
                            return (
                                <div className="ads-margin-seperator">
                                    <Row key={advertisement.adId}>
                                        <Col md={4} sm={12} xs={12}>
                                            <div>
                                                <center>
                                                    <Link to={`/advertisement/${advertisement.adId}`}>
                                                        <img src={project_image} alt={advertisement.adTitle}
                                                            loading="lazy"
                                                            style={{ height: 300, width: '100%', borderRadius: 10, objectFit: 'cover' }} />
                                                    </Link>
                                                </center>
                                            </div>
                                        </Col>
                                        <Col md={8} sm={12} xs={12}>
                                            <div>
                                                <p className="project-date">{project_date} </p>
                                                <Link to={`/advertisement/${advertisement.adId}`}>
                                                    <p className="project-title">{advertisement.adTitle}</p>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                
                            );
                        })
                    }
                </Row>
                ) : (
                    <p className='advt-txt'>Loading</p>
                )

            }
        </>
    )
}
