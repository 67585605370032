import React from "react";
import { withRouter, Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import "./custom.scss";
import "./sidebar_styles.css";

//icons
import logo from "../../assets/logo.png";


class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: false,
    };
  }
  toggleButton = () => {
    this.setState({
      sideBar: !this.state.sideBar,
    });
  };
  render() {
    return (
      <ProSidebar collapsed={this.state.sideBar}>
        <img src={logo} className="menu-logo" />
        <Menu iconShape="square" style={{ marginLeft: -12 }}>
          <MenuItem>
            Home
            <Link to="/" />
          </MenuItem>
          <MenuItem >
            Advertisements
            <Link to={{ pathname: 'https://myplotpic.com/#/ads' }} />
          </MenuItem>
        </Menu>
      </ProSidebar>
    );
  }
}

export default withRouter(SideBar);
