import React, { useEffect, useState } from 'react';
import { getBuilders } from "../api/api";
import BuilderDetails from './BuilderDetails';
import TheSky from './TheSky';

export default function Builder() {
    const [builderSubdomain, setbuilderSubdomain] = useState(null);
    const [BuilderDetail, setBuilderDetail] = useState([]);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        const host = window.location.host;
        const arr = host
            .split(".")
            .slice(0, host.includes("localhost") ? -1 : -2);
        if (arr.length > 0) setbuilderSubdomain(arr[0]);
    }, [BuilderDetail]);

    useEffect(() => {
        const f = async () => {
            const builderInformation = getBuilders(builderSubdomain);
            console.log("BUILDER ====> ", builderSubdomain)
            builderInformation.then((Builderbio) => {
                setBuilderDetail(Builderbio);
                setLoading(false);
            });
        }
        f();
    }, [builderSubdomain]);

    const checkReroute = () => {
        
    }
    return (
        <div>
        {
            builderSubdomain && BuilderDetail && 
            <>
                {
                    isLoading ?
                        <div className='zoom-in-out-box'>Loading..... </div>
                    : 
                        <>
                            {
                                builderSubdomain === "thesky" ?
                                <TheSky BuilderDetail={BuilderDetail} builderSubdomain={builderSubdomain} />
                                :
                                <BuilderDetails BuilderDetail={BuilderDetail} builderSubdomain={builderSubdomain} />
                            }
                        </>
                }
            </>
        }
        </div>
    )
}
