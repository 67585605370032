import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './Advertisement.css'
import profile from "../assets/profile.png";
import SideBar from "../components/Sidebar/Sidebar";
import sidebar_icon from "../assets/Vector.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom'
import { getBuilders, getAdvertisement } from '../api/api'
import BuilderProjectSlides from './BuilderProjectSlides';
import ProjectDetails from '../components/ProjectDetails'

var imagesPool = [
    { src: '/axis.png' },
    { src: "/sbi.png" },
    { src: "/icici.png" },
    { src: "/hdfc.png" }
];
var projectImages = [
    { src: '/slide1.png' },
    { src: "/slide2.png" },
    { src: "/slide4.png" },
    { src: "/slide1.png" },
    { src: "/slide2.png" },
];


export default function AdvertisementDetail() {
    const [sidebar, setSidebar] = useState(false);
    const [builderInfo, setBuilderInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const file_path = 'https://backend.myplotpic.com/uploads/';
    let { adId } = useParams();
    const [adDetail, setadDetail] = useState([]);
    useEffect(() => {
        getAdvertisement().then((advertisement) => {
            const advertisementObj = advertisement.data;
            const filteredBuilderAd = _.filter(advertisementObj, (advertisement) => advertisement.adId === adId);
            setadDetail(filteredBuilderAd[0]);
        });
    }, [adId]);
    useEffect(() => {
        const host = window.location.host;
        const arr = host
            .split(".")
            .slice(0, host.includes("localhost") ? -1 : -2);
        if (arr.length > 0) getBuilders(arr[0]).then((res) => { setBuilderInfo(res); setIsLoading(false) });
    }, []);
    const builderImage = builderInfo.files ? builderInfo.files[0] : '';
    var builderProjects = builderInfo.completed_projects ? builderInfo.completed_projects : 0;
    builderProjects = JSON.parse(builderProjects);

    var allBuildingInfo = adDetail.allBuildingInfo ? adDetail.allBuildingInfo : [];
    allBuildingInfo = JSON.stringify(allBuildingInfo);
    allBuildingInfo = JSON.parse(allBuildingInfo);
    var amenities = adDetail['amenities'] !== undefined ? adDetail['amenities'][0].split(",") : [];
    return (
        <>
            {
                isLoading ? <div className='zoom-in-out-box'>Loading..... </div>
                    : (<>
                        {adDetail && (
                            <>
                                {
                                    sidebar && (<div className='sidebar_view'>
                                        < SideBar />
                                    </div>)
                                }
                                <div className='ads' style={{ width: sidebar ? 'calc(100% - 300px)' : "100%", marginLeft: sidebar ? 300 : 0 }}>
                                    <div className='sidebar'>
                                        <img src={sidebar_icon} style={{ height: 18, width: 18 }} onClick={() => setSidebar(!sidebar)} />
                                    </div>
                                    <div className='ad-view position-relative'>
                                        <div className='adIDposabs'>#{adDetail.adId}</div>
                                        <div className='inner-content-div'>
                                            <Row>
                                                <Col md={12}>
                                                    <div>
                                                        <div className="text-center profile-pic">
                                                            <img src={file_path + builderImage} width={100} className="rounded-circle shadow" />
                                                        </div>
                                                        <div className="text-center mt-3">
                                                            <span className="bg-secondary p-2 px-3 rounded text-white">{adDetail.adTitle}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-5">
                                                <Col md={12}>
                                                    <h6 className="m-0"> <strong>Address: </strong> {adDetail.propertyAddress} </h6>
                                                </Col>
                                            </Row>
                                            <p className='location-txt'>Project Details:</p>
                                            <div className="details-info">
                                                <ProjectDetails adInfo={allBuildingInfo} />
                                            </div>
                                            <p className='location-txt'>Location:</p>
                                            <div className="row d-flex justify-content-start mt-4">
                                                <div className="col-md-8">
                                                    <a target="_blank" href={`https://www.google.com/maps?q=${adDetail.lat},${adDetail.lng}`}>Click here</a>

                                                </div>
                                            </div>
                                            {
                                                amenities && <>
                                                    <p className='location-txt'>Amenities:</p>
                                                    <div className="row d-flex justify-content-start mt-4">
                                                        <div className="col-md-8">
                                                            <ul className="__Amenities_items">
                                                                {
                                                                    _.map(amenities, (val, index) => {
                                                                        return <li key={index}><span>{val}</span></li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {adDetail.viewVirtualLink && <>
                                                <p className='location-txt'>Virtual link:</p>
                                                <div className="row d-flex justify-content-start mt-4">
                                                    <div className="col-md-8">
                                                        <a target="_blank" href={adDetail.viewVirtualLink}>Click here</a>

                                                    </div>
                                                </div></>
                                            }

                                            <p className='location-txt'>Approved By Banks:</p>
                                            <div>
                                                {
                                                    imagesPool.map((image) => <img key={image.src} src={image.src} alt="icons" style={{ height: 70, width: 70, marginLeft: 15 }} />)
                                                }
                                            </div>
                                            <div className='complete-div'>
                                                <p className="completed-txt">Completed Projects <span className="float-end">{_.size(builderProjects)}</span></p>
                                            </div>
                                            <p className='project-txt mx-0'>Photos of Projects:</p>
                                            <div className="scroll scrollmenu">
                                                {

                                                }
                                                <BuilderProjectSlides slides={adDetail.files} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        )}
                    </>)
            }
        </>
    )
}
