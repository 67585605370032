import React from "react";
import _ from 'lodash';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function BuilderProjectSlides({ slides }) {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    const file_path = 'https://backend.myplotpic.com/uploads/';
    return <Carousel>
        {
            slides && _.map(slides, (slide, index) => {
                let imageName = slide.files ? slide.files[0] : slide;
                let imageUrl = file_path + imageName;
                let slideHeading = slide.adTitle ? slide.adTitle : '';
                return <div key={index}><img src={imageUrl} />{slideHeading && <p className="legend">{slide.adTitle}</p>}</div>
            })

        }
    </Carousel>
}
